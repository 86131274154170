import React from 'react';

const AdminTabla = ({ datos, rechazar, validar, contador }) => {
  return (
    <table>
      <thead>
        <tr>
          <td>Nombre</td>
          <td>Placa</td>
          <td>Telefono</td>
          <td>Plan</td>
          <td>Contrato</td>
          <td>Monto.P</td>
          <td>Banco</td>
          <td>Fecha registro</td>
          <td>Referencia</td>
          <td>Acci&oacute;n</td>
        </tr>
      </thead>
      <tbody>
        {datos &&
          datos[contador]?.map((x, indice) => {
            let plan = x.ds_pq.split('-')[1];
            const partes = x?.cd_co?.split('-');
            const resultado =
              partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';
            return (
              <tr key={indice}>
                <td>{x.NOMBRE}</td> <td>{x.pl_vh}</td>
                <td>{x.TFCL01}</td>
                <td>{plan}</td>
                <td>{resultado}</td>
                <td>{x.mt_pg}</td>
                <td>{x.ds_bc}</td>
                <td>{x.fc_rg}</td>
                <td>{x.dt_tp_pg}</td>
                <td>
                  <button
                    onClick={() => rechazar(x)}
                    className=" rechazarButton"
                  >
                    Rechazar
                  </button>
                  <button onClick={() => validar(x)} className=" adminButton">
                    Aprobar
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default AdminTabla;
