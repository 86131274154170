import React, { useState, useEffect, useContext } from 'react';
import logoVr from '../../../img/logo_n_ulimate.png';
import validateUser from '../../Login/Helpers/validateUser';
import { BsX, BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LoginContext } from '../../../context/LoginContext';
import DatosForm from './DatosForm';
import { TokenContext } from '../../../context/TokenContext';
import { useNavigate } from 'react-router-dom';

const IniciarSesion = ({
  verified,
  setVerified,
  handlePrev,
  setMostrarErrores,
  setNavbarErrores,
  opcion,
  setLogin,
  setPaso,
  paso,
  cambiarOpcion,
  setCambiarOpcion,
  locationPath,
}) => {
  const { setLog } = useContext(LoginContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (locationPath?.state?.location?.pathname != null) {
      localStorage.setItem(
        'RCV_DIGITAL_LOCATION',
        JSON.stringify(locationPath.state.location.pathname)
      );
    }
  }, []);

  const locationCon = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOCATION'));

  const [datosForm, setDatosForm] = useState({
    usuario: {
      us_us: '',
      pw_us: '',
    },
  });

  const datosDelFormulario = e => {
    const { name, value } = e.target;

    setDatosForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePrevPago = e => {
    e.preventDefault();
    if (paso > 0) {
      setTimeout(() => {
        setPaso(paso - 2);
      }, 300);
    }
  };

  const loguearse = async e => {
    setLoading(true);
    e.preventDefault();
    let respuesta = await validateUser(datosForm, setToken, setLoading);
    setVerified(respuesta);
  };

  useEffect(() => {
    if (verified?.response === true) {
      setLoading(false);
      if (location.pathname === '/proceso/pago') {
        setTimeout(() => {
          setLogin(true);
          setLog(true);
        }, 300);
      } else {
        if (!locationCon) {
          setLog(true);

          navigate('/inicio');
        } else {
          setLog(true);
          navigate(locationCon);
        }
      }
    } else if (verified?.status === false) {
      setLoading(false);
      setMostrarErrores(true);
      setNavbarErrores([verified.message]);
    }
  }, [verified]);

  return (
    <div
      className={`iniciarSesion ${opcion === 3 ? 'ocultar' : ''} ${
        cambiarOpcion === true ? 'opcion' : ''
      }`}
    >
      <div className="iniciarSesion__container">
        <div className="iniciarSesion__container-logo">
          <img
            loading="lazy"
            alt="logo de Venezuela Responsable"
            src={logoVr}
          />
        </div>
        <div className="iniciarSesion__container-header">
          <h4>Inicia sesi&oacute;n en tu cuenta</h4>
        </div>
        <DatosForm
          loguearse={loguearse}
          datosDelFormulario={datosDelFormulario}
          datosForm={datosForm}
          setDatosForm={setDatosForm}
          opcion={opcion}
          loading={loading}
        />
        <Link to={'/recuperacontrasena'}>¿Olvidaste tu contraseña?</Link>

        <div className="iniciarSesion__container-opcion">
          <span>No tengo cuenta</span>{' '}
          <button
            onClick={() => {
              cambiarOpcion ? setCambiarOpcion(false) : setCambiarOpcion(true);
            }}
          >
            REGISTRARSE
          </button>
        </div>
      </div>{' '}
      {handlePrev === null || handlePrev === undefined ? null : (
        <div className="proceso__botones">
          <div></div>
          <div
            className="proceso__botones-contenedor"
            style={{ position: 'absolute', bottom: '5px', right: '5px' }}
          >
            <Link to={'/'} className="cancelar">
              CANCELAR <BsX />
            </Link>
            <button className="anterior" onClick={handlePrevPago}>
              ANTERIOR <BsArrowLeft />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IniciarSesion;
