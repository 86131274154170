import React from 'react';

const AdminCartas = ({ indice, plan, rechazar, x, validar }) => {
  return (
    <div className="adminDashboard__body-item" key={indice}>
      <div className="adminDashboard__body-item-header">
        <span className="adminDashboard__body-item-header-nombre">
          {x.NOMBRE}
        </span>
        <span className="adminDashboard__body-item-header-placa">
          {x.pl_vh}
        </span>
      </div>
      <div className="adminDashboard__body-item-body">
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Telefono
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {x.TFCL01}
          </span>
        </div>
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Plan
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {plan}
          </span>
        </div>
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Monto pagado
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {x.mt_pg}
          </span>
        </div>
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Banco
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {x.ds_bc}
          </span>
        </div>
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Fecha registro
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {x.fc_rg}
          </span>
        </div>
        <div className="adminDashboard__body-item-body-caracteristica">
          <span className="adminDashboard__body-item-body-caracteristica-nombre">
            Referencia
          </span>
          <span className="adminDashboard__body-item-body-caracteristica-valor">
            {x.dt_tp_pg}
          </span>
        </div>
      </div>
      <div className="adminDashboard__body-item-footer">
        <button onClick={() => rechazar(x)} className=" rechazarButton">
          Rechazar
        </button>
        <button onClick={() => validar(x)} className=" adminButton">
          Aprobar
        </button>
      </div>
    </div>
  );
};

export default AdminCartas;
