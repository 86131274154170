import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imageRCV from '../../styles/images/IMG_20230727_151123-_2_.webp';
import Consultar from '../Consultar/Consultar';
import IniciarHero from './IniciarHero/IniciarHero';
import NavbarErrors from '../NavbarLite/NavbarErrors';

export const HeroIndex = ({ version }) => {
  const [cargando, setCargando] = useState(true);
  const [mostrarGenerar, setMostrarGenerar] = useState(false);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  useEffect(() => {
    setTimeout(() => {
      setCargando(false);
    }, 1000);
  }, []);
  return (
    <section className={`heroindex ${login ? 'active' : null}`}>
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <Consultar
        mostrarGenerar={mostrarGenerar}
        setMostrarGenerar={setMostrarGenerar}
      />
      <div>
        <header className={`heroindex__header ${login ? 'active' : null}`}>
          <h1>
            Adquiere tu <span>RCV</span>
          </h1>
        </header>

        <div className={`heroindex__parrafo ${login ? 'active' : null}`}>
          <p>
            Aquí podrás adquirir tu rcv. Elige el mejor plan para ti y deja las
            preocupaciones.
          </p>
        </div>
        <div
          className={`heroindex__buttonContainer ${login ? 'active' : null}`}
        >
          <div className="heroindex__buttonContainer-container">
            {' '}
            <Link to={cargando ? '#' : '/proceso/clases'}>
              <button className="heroindex__buttonContainer-button">
                {cargando ? (
                  <div className="lds-ellipsisg">
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                  </div>
                ) : (
                  'COMPRAR'
                )}
              </button>
            </Link>
            <span className="heroindex__buttonContainer-container-version">
              {' '}
              Versi&oacute;n: {version}
            </span>
          </div>

          {/** 
          <button onClick={() => setMostrarGenerar(true)}>CONSULTAR</button>
            */}
        </div>
      </div>
      {!login ? (
        <IniciarHero
          setNavbarErrores={setNavbarErrores}
          setMostrarErrores={setMostrarErrores}
        />
      ) : null}

      <div className="heroindex__banner"></div>
      <img
        src={imageRCV}
        alt="imagen-venezuela-responsable"
        loading="lazy"
        className="heroindex__banner-img"
      />
    </section>
  );
};
