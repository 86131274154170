import Facts from '../components/Facts/Facts';
import Footer from '../components/Footer/Footer';
import { useEffect, useContext } from 'react';
import { AboutUs } from '../components/sections/AboutUs';
import { WhyChooseUs } from '../components/sections/WhyChooseUs';
import { Services } from '../components/sections/Services';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Contact from './Contact';
import { HeroIndex } from '../components/HeroIndex/HeroIndex';
import BannerImage from '../components/BannerImage/BannerImage';
import { getToken } from '../helpers/getToken';
import SucursalesHero from '../components/Sucursales/SucursalesHero';
import { LoginContext } from '../context/LoginContext';

const Home = () => {
  const { state } = useLocation();

  const loginConfirm = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (loginConfirm === null || loginConfirm === undefined) {
      const getTokens = async () => {
        const dataToken = await getToken();
        if (token === null || token === undefined) {
          localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(dataToken));
        }
      };
      getTokens();
    }
    if (state?.tokenData === true) {
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.reload();
    }
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_REGISTROA');
    localStorage.removeItem('RCV_CODIGO_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_PASO');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_USDESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_LOCATION');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('TOTAL_PAGO');
    localStorage.removeItem('RCV_DIGITAL_PASO_VERIFICADO');
    localStorage.removeItem('RCV_DIGITAL_S/P');
    localStorage.removeItem('RCV_DIGITAL_DESCONTADO');
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const [banner, setBanner] = useState(localStorage.getItem('sibanner'));
  const { tasa } = useContext(LoginContext);

  useEffect(() => {
    const bannerCom = localStorage.getItem('nobanner');
    if (bannerCom != null || bannerCom != undefined) {
    } else {
      localStorage.setItem('sibanner', true);
    }
  }, []);

  return (
    <div className="home">
      {banner != null || banner != undefined ? <BannerImage /> : null}

      <HeroIndex version={tasa?.ve_si} />

      <br />
      <Facts offset={offset} />
      <AboutUs />
      <Services />
      <WhyChooseUs offset={offset} />
      <SucursalesHero />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
