import React, { useContext, useState, useEffect } from 'react';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import Pagination from '../../Pagination/Pagination';
import NavbarErrors from '../../NavbarLite/NavbarErrors';
import HistoryAdministrationCards from './HistoryAdministrationCards';

const HistoryAdministration = () => {
  const [history, setHistory] = useState([]);
  const [fechaIni, setFechaIni] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [cargando, setCargando] = useState(false);
  const [datos, setDatos] = useState([]);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [filtroEstatus, setFiltroEstatus] = useState('TODOS');
  const [page, setPage] = useState();
  const [contador, setContador] = useState(0);
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);

  const cambiarFecha = (e, tipo) => {
    e.preventDefault();
    if (tipo === 'ini') {
      setFechaIni(e.target.value);
    } else {
      setFechaFin(e.target.value);
    }
  };

  function getPrimerDiaDelMes() {
    const hoy = new Date();
    setFechaIni(
      new Date(hoy.getFullYear(), hoy.getMonth(), 1).toISOString().substr(0, 10)
    );
  }

  function getUltimoDiaDelMes() {
    const hoy = new Date();
    setFechaFin(
      new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10)
    );
  }

  useEffect(() => {
    getPrimerDiaDelMes();
    getUltimoDiaDelMes();
    setTimeout(() => {
      document.getElementById(`botonBusca`).click();
    }, 1000);
  }, []);

  const historyUrl = `v1/getContractList?p1=&p2=10&p3=&p4=&dd=${fechaIni}&ht=${fechaFin}&op=9&token=`;

  useEffect(() => {
    const filtrarPorEstatus = (contratos, estatus) => {
      if (!Array.isArray(contratos)) return [];
      if (estatus === 'TODOS') return contratos;

      return contratos.filter(contrato => contrato?.es_rg === estatus);
    };

    const contratosFiltrados = filtrarPorEstatus(history, filtroEstatus);

    const grupos = Array.isArray(contratosFiltrados)
      ? contratosFiltrados.reduce((acc, _, index) => {
          if (index % 10 === 0) {
            acc.push(contratosFiltrados.slice(index, index + 10));
          }
          return acc;
        }, [])
      : [];

    setDatos(grupos);
    setPage(grupos.length - 1);
  }, [history, filtroEstatus]);

  const buscar = e => {
    e.preventDefault();
    setCargando(true);
    if (!fechaIni || !fechaFin) {
      setCargando(false);
      setNavbarErrores(['Debes colocar las fechas']);
      setMostrarErrores(true);
    } else {
      consultarApi(
        historyUrl,
        0,
        data => {
          setHistory(Array.isArray(data) ? data : [data]);
        },
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(() => setCargando(false));
    }
  };

  return (
    <div className="tableHistory">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div className="filtroEstatus">
        <label htmlFor="estatus">Filtrar por estado: </label>{' '}
        <select
          id="estatus"
          value={filtroEstatus}
          onChange={e => setFiltroEstatus(e.target.value)}
        >
          <option value="TODOS">TODOS</option>
          <option value="P">PENDIENTE</option>
          <option value="A">ACTIVO</option>
          <option value="R">RECHAZADO</option>
        </select>
      </div>
      <div className="tableHistory__dates">
        <input
          className="reportes__container-seleccion-date"
          type="date"
          onChange={e => cambiarFecha(e, 'ini')}
          value={fechaIni}
        />
        <input
          className="reportes__container-seleccion-date"
          type="date"
          onChange={e => cambiarFecha(e, 'fin')}
          value={fechaFin}
        />
        <button
          id="botonBusca"
          className="botonpc"
          onClick={buscar}
          disabled={cargando === true ? true : false}
        >
          {cargando === false ? (
            'Aceptar'
          ) : (
            <div className="lds-ellipsisg">
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
            </div>
          )}
        </button>
      </div>
      {datos[contador]?.map((x, indice) => {
        return (
          <HistoryAdministrationCards key={indice} indice={indice} x={x} />
        );
      })}
      {datos && Array.isArray(datos[contador]) && datos[contador].length > 0 ? (
        <div>
          {cargando && !history ? (
            <div>cargando</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <td>Nombre</td>
                  <td>Placa</td>
                  <td>Telefono</td>
                  <td>Plan</td>
                  <td>Contrato</td>
                  <td>Monto.P</td>
                  <td>Estado</td>
                  <td>Observacion</td>
                  <td>Banco</td>
                  <td>Fecha registro</td>
                  <td>Referencia</td>
                </tr>
              </thead>
              <tbody>
                {datos[contador]?.map((x, indice) => (
                  <tr key={indice}>
                    <td>{x?.contratante}</td>
                    <td>{x?.placa}</td>
                    <td>{x?.telefono}</td>
                    <td>{x?.ds_of}</td>
                    <td>{x?.cd_co}</td>
                    <td>{x?.mt_sb_tt}</td>
                    <td>
                      {x?.es_rg === 'A'
                        ? 'ACTIVO'
                        : x?.es_rg === 'R'
                        ? 'RECHAZADO'
                        : x?.es_rg === 'P'
                        ? 'PENDIENTE'
                        : 'VENCIDO'}
                    </td>
                    <td>{x?.ob_ac === '' ? 'S/N' : x?.ob_ac}</td>
                    <td>{x?.ds_bc}</td>
                    <td>{x?.fecha}</td>
                    <td>{x?.dt_tp_pg}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <div>No hay contratos en este rango de fecha</div>
      )}

      {page === 0 ? null : (
        <Pagination
          page={page}
          contador={contador}
          setContador={setContador}
          datos={datos}
        />
      )}
    </div>
  );
};

export default HistoryAdministration;
