import React from 'react';

const HistoryAdministrationCards = ({ indice, x }) => {
  return (
    <div className="historyAdmin__body-item" key={indice}>
      <div className="historyAdmin__body-item-header">
        <span className="historyAdmin__body-item-header-nombre">
          {x.contratante}
        </span>
        <span className="historyAdmin__body-item-header-placa">{x.pl_vh}</span>
      </div>
      <div className="historyAdmin__body-item-body">
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Placa
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.placa}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Telefono
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.telefono}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Contrato
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.cd_co}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Monto pagado
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.mt_sb_tt}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Estado
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x?.es_rg === 'A'
              ? 'ACTIVO'
              : x?.es_rg === 'R'
              ? 'RECHAZADO'
              : x?.es_rg === 'P'
              ? 'PENDIENTE'
              : 'VENCIDO'}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Observacion
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x?.ob_ac === '' ? 'S/N' : x?.ob_ac}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Banco
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.ds_bc}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Fecha registro
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.fecha}
          </span>
        </div>
        <div className="historyAdmin__body-item-body-caracteristica">
          <span className="historyAdmin__body-item-body-caracteristica-nombre">
            Referencia
          </span>
          <span className="historyAdmin__body-item-body-caracteristica-valor">
            {x.dt_tp_pg}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HistoryAdministrationCards;
