import actualizarTokenNoLog from './actualizarTokenNoLog';

const consultarApi = async (
  url,
  trace,
  setFunc,
  setTokenCambiar,
  setTokenVencido,
  setToken,
  cancelar
) => {
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL_API}${url}${token}&trace=${trace}`
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw { response, errorData };
    }

    const data = await response.json();
    setFunc(data.data);

    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.token));
    setTimeout(() => {
      localStorage.removeItem('RCV_DIGITAL_TOKEN_AUTH');
    }, 2000);
  } catch (error) {
    console.log(error);
    if (error?.response && error?.response?.status === 410) {
      cancelar();
    }
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        setTokenVencido(error.errorData.token);
        setTokenCambiar(true);
        console.log(error);
      }
    }
  }
};

export default consultarApi;
